import React, { useEffect, createContext, useState } from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "./pages/Home";
import $axios from "./services/axios";
import { Modal } from "antd";

const ReachableContext = createContext(null);
const config = {
  title: "Token không hợp lệ!",
  maskClosable: false,
  footer: null,
  content: (
    <>
      <ReachableContext.Consumer>
        {(name) => `Có lỗi xảy ra vui lòng trở về trang chủ`}
      </ReachableContext.Consumer>
    </>
  ),
};

const App = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [token, setToken] = useState(localStorage.getItem("planeToken"));
  const history = useHistory();
  const location = useLocation();

  const decodeToken = async () => {
    // Lấy token từ URL
    try {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get("id_request") || localStorage.getItem("planeToken")) {
        const IdRequest = queryParams.get("id_request");
        const CifPk = queryParams.get("cif_pk");
        const DeviceId = queryParams.get("device_id");
        const RequestDate = queryParams.get("request_date");
        const Signature = queryParams.get("signature");
        //https://nama.ipass360.vn/vemaybay?id_request=68e23cd00001bc1100060193a987ec7f&cif_pk=d68ff7fa0001bc11000701793ab9062d&device_id=0DCBA233A1B437613370F3F5961F1426&request_date=1733716077773&signature=L59yLGstFIEesZGZXJ3J%2Fz2KGwJ1QT7CD4VUZFduQ%2BFTP6egsO5QueZgm9lZeONmkCIfpGYWTp5fdJdqJvfWfnE2RXa0mZ5hgGUejRwRwATmGRcOKC1hQVMAW1CVSxuRtM%2BsWlvpIEM6t0K2T%2FfFxyUUt46qGehZVdOlwKkzrXr89doxwavYy%2FZIod3k%2FOAIIlU%2BrjJ%2BzOGfjSG4qJRf0xMrCysXNzygCb7hUZrhz5ZkEmCoIS8mgrCLjq1d3dl9C9owm%2FDyda1Zf6yvAEjvFs18M54L4NeZTsPtdyKCvNcCJgUoJPFVgMDYfLsIWOqWoysX34sb%2FNglX1gavsDXgg%3D%3D
        if (IdRequest && CifPk && DeviceId && RequestDate && Signature) {
          const res = await $axios.$post("/login", {
            IdRequest,
            CifPk,
            PartnerId: "NAB",
            DeviceId,
            RequestDate,
            Signature,
          });
          localStorage.setItem("planeToken", res.data.data.token);
          localStorage.setItem("planeUserInfo", JSON.stringify(res.data.data.userInfo));
          setToken(res.data.data.token);
          history.replace({
            pathname: location.pathname,
          });
        }
      } else {
        modal.error(config);
      }
    } catch (error) {}
  };

  useEffect(() => {
    decodeToken();
  }, []);

  return (
    <>
      <Switch>
        <Redirect exact from="/" to={"/vemaybay"} />
        <Route
          exact
          path={"/vemaybay"}
          render={(props) => <Home {...props} token={token} />}
        />
        <Redirect to={"/vemaybay"} />
      </Switch>
      {contextHolder}
    </>
  );
};

export default App;
